@media only screen and (min-width: 1024px) {
    .monthly-hits-card {
      margin-left: 5%;
      margin-top: 4.6%;
      padding-left: 3%;
    }
  
    .monthly-hits-card-title {
      text-align: left;
      font-weight: 700;
    }
  
    .monthly-hits-card-sub-title {
      font-size: 13px;
      margin-top: -6% !important;
      color: #94a0b1;
    }
  
    .monthly-hits-footer {
      width: 120.7% !important;
      margin-left: -12.5% !important;
      margin-bottom: -8.3%;
      height: 30% !important;
      font-size: 12px;
      line-height: 16.8px;
      text-align: left;
      background-color: hsl(0%, 0%, 98%);
      color: hsl(0, 0%, 50%);
    }
  
    .monthly-hits-card .recharts-yAxis {
      display: block !important;
    }
  
    .monthly-hits-card .recharts-xAxis {
      display: none !important;
    }
  
    .monthly-hits-card-area-chart {
      margin-left: -30%;
      margin-bottom: -14%;
    }
  
    .customized-y-axis-tick-text {
      font-size: 12px;
    }
  }
  
  @media only screen and (min-width: 308px) and (max-width: 1024px) {
    .monthly-hits-card {
      margin-top: 5%;
      margin-left: 3.2%;
      width: 94.5%;
      border-radius: none !important;
      max-height: 10% !important;
      padding-left: 3%;
    }
  
    .monthly-hits-card-title {
      text-align: left;
      font-weight: 700;
    }
  
    .monthly-hits-card-sub-title {
      font-size: 13px;
      margin-top: -4% !important;
      color: #94a0b1;
    }
  }
  
  @media only screen and (min-width: 1666px) {
    .monthly-hits-card {
      margin-left: 20%;
      margin-top: 3.5%;
      margin-right: 1%;
      padding-left: 3%;
      width: 93% !important;
    }
  
    .monthly-hits-card-title {
      text-align: left;
      font-weight: 700;
    }
  
    .monthly-hits-card-sub-title {
      font-size: 13px;
      margin-top: -4% !important;
      color: #94a0b1;
      margin-bottom: 25.5% !important;
    }
  
    .monthly-hits-card-body {
      min-height: 30% !important;
    }
  
    .monthly-hits-footer {
      width: 114.5% !important;
      margin-left: -9% !important;
      margin-bottom: -5.6%;
      height: 30% !important;
      font-size: 12px;
      line-height: 16.8px;
      text-align: left;
      background-color: hsl(0%, 0%, 98%);
      color: hsl(0, 0%, 50%);
    }
  
    .monthly-hits-card .recharts-yAxis {
      display: block !important;
    }
  
    .monthly-hits-card .recharts-xAxis {
      display: block !important;
    }
  
    .monthly-hits-card-area-chart {
      margin-left: -20%;
      margin-bottom: -1%;
      margin-top: -20%;
      margin-right: -550% !important;
      z-index: 1;
    }
  
    .customized-y-axis-tick-text {
      font-size: 12px;
    }
  
    .subscribers-by-channel-tooltip {
      width: 120% !important;
      background-color: #111;
      fill: #111;
      border: #111 1px solid;
    }
    .subscribers-by-channel-tooltip-label {
      font-size: 13px;
      font-weight: 700;
      margin-left: 10%;
      width: 100% !important;
    }
  
    .subscribers-by-channel-tooltip-value {
      font-size: 13px;
      margin-left: 10%;
      margin-right: 5%;
      margin-top: -20% !important;
      margin-bottom: -0%;
    }
  }
  