.countdown-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0.5rem
  }
  
  .countdown-container > form {
    padding: 0.5rem;
  }
  
  .countdown-container > form > label {
    margin-right: 1rem;
  }
  
  .expired-notice {
    text-align: center;
    padding: 1rem;
    border: 1px solid gray;
    border-radius: 20px;
    margin: 0.5rem;
    background-color: rgba(32,32,43,0.7);
  }
  
  .expired-notice > span {
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
  }
  
  .expired-notice > p {
    font-size: 1.5rem;
  }
  
  .show-counter {
    /* padding-bottom: 10px; */
    /* background-color: rgba(32,32,43,0.7); */
    /* border: 1px solid gray; */
    /* border-radius: 20px; */
  }
  
  .show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: 'center';
    font-weight: 700;
    /* font-size: 2.25rem; */
    /* line-height: 1.75rem; */
    text-decoration: none;
    color: white;
    max-height: 70px;
    /* box-shadow: 10px 10px #cfc9c9; */
  }
  
  .show-counter .countdown {
    /* line-height: 1.25rem; */
    padding: 0 0.25rem 0 0.25rem;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .show-counter .countdown.danger {
    color: #ff0000;
  }
  
  /* .show-counter .countdown > p {
    margin: 0px;
  } */
  
  .show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 1.50rem;
    line-height: 2rem;
  }
  
  @media only screen and (max-width: 500px) {
    .show-counter .countdown > span {
      font-size: 1rem;
      line-height: 1rem;
    }
  
    .show-counter .countdown-link { 
      font-size: 1.5rem;
    }
  }