body,
html,
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif ;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-y: auto;
  height: 100%;
}

body {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center center !important;
    background-attachment: fixed !important;
    background-size: cover !important;
  }
  
  .app {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }